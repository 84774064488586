import React from 'react'

export function AppFlow() {
  return (
    <div className='w-100 card'>
      <iframe style={{border: "1px solid rgba(0, 0, 0, 0.1)", borderRadius: "8px", width:"100%", height: "70vh", minHeight:"450"}} src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FQTPxFitCprqwGOUDpZM6X8%2FSmart-Banking%3Fnode-id%3D0%253A1%26t%3DSu6SeNnGllD2XUIW-1" allowFullScreen></iframe>
    </div>
    
  )
}

